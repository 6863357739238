<template>
    <div>
        <CreateDocument />
        <DocumentView />
    </div>
</template>

<script>
import CreateDocument from './components/CreateDocument'
import DocumentView from './components/DocumentView.vue'
export default {
    components: {
        CreateDocument,
        DocumentView
    }
}
</script>